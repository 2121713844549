@font-face {
  font-family: "CairoRegular";
  font-weight: 400;
  font-style: normal;
  src: url("./fonts/cairo.ttf") format("truetype");
}

@font-face {
  font-family: "CairoMedium";
  font-weight: 500;
  font-style: normal;
  src: url("./fonts/cairo.ttf") format("truetype");
}

@font-face {
  font-family: "CairoSemiBold";
  font-weight: 600;
  font-style: normal;
  src: url("./fonts/cairo.ttf") format("truetype");
}

@font-face {
  font-family: "CairoBold";
  font-weight: 700;
  font-style: normal;
  src: url("./fonts/cairo.ttf") format("truetype");
}

@font-face {
  font-family: "MeriendaRegular";
  font-weight: 400;
  font-style: normal;
  src: url("./fonts/merienda.ttf") format("truetype");
}
